<template>
  <div>
    <a-row :gutter="[0, 20]">
      <a-col class="opportunity" :span="24">
        <div class="wrapper">
          <a-row type="flex" justify="space-between">
            <a-col class="name">
              #{{ opportunity.id }} -
              {{ opportunity.title }}
            </a-col>

            <a-col class="icos">

            </a-col>
          </a-row>

          <a-row class="mt-10" type="flex" align="middle">
            <a-col :span="2">
              <div class="label">Status</div>
              <div class="value">
                <span style="color: #333">
                  <div>
                    {{ opportunity.status }}
                  </div>
                </span>
              </div>
            </a-col>

            <a-col :span="4">
              <div class="label">Etapa</div>
              <div class="value">
                <span style="color: #333">
                  <div>
                    {{ opportunity.step }}
                  </div>
                </span>
              </div>
            </a-col>

            <a-col :span="3">
              <div class="label">Data fechamento</div>
              <div class="value">
                {{
                  opportunity.release_date == "0000-00-00"
                    ? formatDateTime(opportunity.created)
                    : formatMultiDates(opportunity.release_date)
                }}
              </div>
            </a-col>

            <a-col :span="4">
              <div class="label">Origem</div>
              <div class="value">
                {{ opportunity.origin ? opportunity.origin : "Nenhuma" }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Valor</div>
              <div class="value">
                {{ opportunity.value | formatPricePtBr }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Notas</div>
              <div class="value">
                {{ opportunity.total_notes }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Arquivos</div>
              <div class="value">
                {{ opportunity.total_files }}
              </div>
            </a-col>

            <a-col :span="2">
              <div class="label">Consultor</div>
              <div class="value">
                <a-tooltip v-if="opportunity.user">
                  <template slot="title">
                    {{ opportunity.user.first_name }}
                    {{ opportunity.user.last_name }}
                  </template>
                  <span id="avatar">
                    <a-avatar
                      v-if="opportunity.user.avatar"
                      :src="opportunity.user.avatar"
                      :size="30"
                    />
                    <a-avatar
                      v-else
                      class="upper"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ opportunity.user.first_name.substring(0, 1)
                      }}{{ opportunity.user.last_name.substring(0, 1) }}
                    </a-avatar>
                  </span>
                </a-tooltip>
              </div>
            </a-col>

            <a-col :span="3">
              <a-button
                class="more f11"
                size="small"
                type="primary"
                @click="$router.push(`/opportunity/edit/${opportunity.id}`)"
              >
                ver detalhes
              </a-button>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
export default {
  name: "CustomerOpportunityCard",
  props: {
    opportunity: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.opportunity
  .wrapper:hover
    box-shadow: 0px 0px 10px #ddd
  .wrapper
    padding: 15px
    border: 1px solid #eee
    border-radius: 6px
    transition: .3s
    .more
      width: 100%
      background: #6a50a7
      border: 1px solid #6a50a7
    .label
      font-size: 11px
      color: #999
    .value
      font-size: 11px
      font-weight: 600
</style>
